import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  buildImageObj
} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import GraphQLErrorList from '../components/graphql-error-list'

import Container from '../components/container'
import PortableText from '../components/portableText'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import Page from '../components/page'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query TermsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      heroImage{
        ...SanityImage
        alt
      }
      title
      subTitle
      _rawIntro
      _rawFooter
      description
      keywords
    }

    page: sanityPages(
      slug: { current: {eq: "terms"} }
    ) {
      title
      titleEn
      _rawBody(resolveReferences: {maxDepth: 5})
      _rawExcerpt
      mainImage{
        ...SanityImage
        alt
      }
    }
  }
`

const TermsPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const page = (data || {}).page

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={page.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        {page && (
          <Page
            title={page.title}
            titleEn={page.titleEn}
            mainImage={page.mainImage}
            _rawBody={page._rawBody}
            _rawExcerpt={page._rawExcerpt}
          />
        )}
      </Container>
    </Layout>
  )
}

export default TermsPage
